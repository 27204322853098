import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getCurrentUser, fetchUserAttributes, signInWithRedirect, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { Observable, from } from 'rxjs';
import { PermissionsService, UserLevel } from './services/permissions.service';
import { LicenseManager } from "ag-grid-enterprise";
import { environment } from './../environments/environment';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061654}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SIEMENS_MOBILITY_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cab_Radio_Management_Terminal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Cab_Radio_Management_Terminal}_need_to_be_licensed___{Cab_Radio_Management_Terminal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_July_2025}____[v3]_[01]_MTc1MjM2MTIwMDAwMA==0c3670a7abc472d12b97aabf3e7c494a")
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'crmt-gui';

  public name$!: Observable<string>;

  public disableStandard = true
  public disableSuper = true
  public isLoading = true;
  public applicationName = environment.applicationName
  public applicationVersion = environment.applicationVersion

  isAuthenticated = false;
  constructor(
    private readonly permissions: PermissionsService,
    private _router: Router, private authenticator: AuthenticatorService) { }

  public ngOnInit() {
    this.checkAuthenticationStatus();
  }

  public get isAuthorized(): Observable<boolean> {
    return from(fetchAuthSession().then(() => true).catch(() => false));
  }

  private checkAuthenticationStatus() {
    // Check if the user is authenticated
    getCurrentUser()
      .then(user => {
        fetchUserAttributes()
        .then(attributes => {
          this.name$ = new Observable(observer => {
            observer.next(attributes.name);
            observer.complete();
          });
          this.permissions.getUserLevel().subscribe(level => {
            this.disableStandard = level < UserLevel.STANDARD
            this.disableSuper = level < UserLevel.SUPER
            if (this.isAuthorized && level > 0) {
              this.isLoading = false;
            }
          })
        })
        .catch(err => {
          console.error('Error retrieving session:', err);
        });
      })
      .catch(() => {
        this.redirectToCognitoLogin();
      });
  }

  private async redirectToCognitoLogin() {
    await signInWithRedirect() 
  }


async handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}

export const environment = {
  production: false,
  applicationName: require('../../../crmt-gui/package.json').name,
  applicationVersion: require('../../../crmt-gui/package.json').version,
  
  cognitoConfig: {
    userPoolId: 'eu-west-2_RqQ2u7YaS',
    userPoolClientId: '3rpdvs9v8slj1qm5n5u1arn68l',
    domain: 'https://crmt-dev.auth.eu-west-2.amazoncognito.com',
    redirectSignInURL: [window.location.origin + '/login/callback'],
    redirectSignOutURL: [window.location.origin],
  },

  backendConfig: {
    url: "https://crmt-dev-api.64836216846.com/"
  },
  configs:{
    attachedSystemsParametersSystemValues : ["Not Used","Intercom","Public Address","ERTMS/ETCS","Train-borne Recorder","Not Used","Not Used","Not Used"],
    trainCrewParametersCrewMemberValues : ["Driver 2","Driver 3","Driver 4","Driver 5","Chief Conductor","Chief Caterer","Chief Security","Departure Procedure Lead Driver","Departure Procedure Chief Conductor"]
  }
};

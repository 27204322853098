import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';
import { environment } from './../../environments/environment';
import { fetchAuthSession, signInWithRedirect } from "aws-amplify/auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.addAuthHeaderToAllowedOrigins(request)).pipe(
      switchMap(requestObject =>{
        return next.handle(requestObject)
      })
    );
  }

  private async addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
    let req = request;
    const allowedOrigins = [
      environment.backendConfig.url
    ];
    
    if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
      const { tokens } = await fetchAuthSession();
      const idToken = tokens?.idToken?.toString();

      if(!idToken) {
        signInWithRedirect()
      }
      
      req = request.clone({ setHeaders: { 'Authorization': `Bearer ${idToken}` } });
    }
    return req;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import { FileEditPageComponent } from './pages/file-edit-page/file-edit-page.component';
import { DeployFileComponent } from './pages/deploy-file/deploy-file.component';
import { FleetPageComponent } from './pages/fleet-page/fleet-page.component';
import { RetrieveEditDeployComponent } from './pages/retrieve-edit-deploy/retrieve-edit-deploy.component';
import { JobListComponent } from './pages/job-list/job-list.component';
import { SendCommandPageComponent } from './pages/send-command-page/send-command-page.component';
import { AuditLogComponent } from './pages/audit-log/audit-log.component';
import { SuperUserGuard } from './guards/super-user.guard';
import { UploadFileComponent } from './pages/upload-file/upload-file.component';
import { DeviceInfoComponent } from './pages/device-info/device-info.component';
import { FleetListPageComponent } from './pages/fleet-list-page/fleet-list-page.component';
import { DeviceRegistrationPageComponent } from './pages/device-registration-page/device-registration-page.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthGuard } from './guards/auth-guard.guard';


const routes: Routes = [
  { path: 'login/callback', component: CallbackComponent },
  {
    path: '', canActivate: [AuthGuard], children: [
      { path: 'profile', component: ProfileComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'fleet-management', component: FleetPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'fleet-list', component: FleetListPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'file-editor', component: FileEditPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'deploy-file', component: DeployFileComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'upload-file', component: UploadFileComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'device-config', component: RetrieveEditDeployComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'device-registration', component: DeviceRegistrationPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'fleet-list/device-details/:deviceId/:thingStockNumber', component: DeviceInfoComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'fleet-management/device-details/:deviceId/:thingStockNumber', component: DeviceInfoComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'job-list', component: JobListComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'send-command', component: SendCommandPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'audit-log', component: AuditLogComponent, canActivate: [SuperUserGuard] },
      { path: '', redirectTo: '/fleet-list', pathMatch: 'full', canDeactivate: [CanDeactivateGuard] }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
